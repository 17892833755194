<template>
  <div class="park">
    <div class="park-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentComp: "padContent",
      isActive: "1",
      menu: [
        {
          name: "PDA测试",
          value: "1",
          comp: "padContent",
        },
        {
          name: "PES报告",
          value: "2",
          comp: "pesContent",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
  components: {},
};
</script>
<style lang="scss" scoped>
.park-rou {
  position: relative;
  ul {
    display: flex;
    li {
      width: 50%;
      height: 440px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
      a {
        display: block;
      }
      .con {
        cursor: pointer;
      }
      .con-title {
        font-size: 68px;
        font-family: Arial Black;
        font-weight: 400;
        color: #ffffff;
      }
      .con-es {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.6;
        margin-bottom: 25px;
      }
      .con-ch {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .pda {
      background: url(../../../assets/img/pda/Pic_Bg_CS_PDA.png) no-repeat;
      background-size: 100% 100%;
    }
    .pes {
      background: url(../../../assets/img/pda/Pic_Bg_Cs_PES.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
.preball {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    width: 280px;
    height: 282px;
  }
}
</style>